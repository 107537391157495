<template>
  <div id="profile">
    <breadcrumb class="mt-3" :item="$route.matched" :current="$route.path" />

    <h5 class="mt-3">Profile</h5>

    <hr />

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <b-overlay :show="loading">
      <div class="profile-container">
        <div class="profile-container__form">
          <form @submit.prevent="updateData" v-if="!loading">
            <div class="row">
              <div class="col">
                <!-- First name -->
                <text-field
                  v-model="userData.firstName"
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  placeholder="Enter First Name"
                  :errors="errors"
                />
              </div>
              <div class="col">
                <!-- Last name -->
                <text-field
                  v-model="userData.lastName"
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  :errors="errors"
                />
              </div>
            </div>

            <!-- Language -->
            <dropdown
              v-model="userData.language"
              id="language"
              name="language"
              label="Language"
              :options="options.languageOpt"
              :errors="errors"
            />

            <!-- Email -->
            <text-field
              v-model="userData.email"
              id="email"
              name="email"
              label="Email"
              type="email"
              placeholder="Enter Email"
              :errors="errors"
            />

            <div class="row">
              <div class="col">
                <!-- Phone -->
                <phone-number-input
                  v-model="userData.phoneNumber"
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Enter Phone Number"
                  :errors="errors"
                />
              </div>
              <div class="col">
                <!-- Mobile -->
                <phone-number-input
                  v-model="userData.mobileNumber"
                  id="mobileNumber"
                  name="mobileNumber"
                  label="Mobile Number"
                  placeholder="Enter Mobile Number"
                  :errors="errors"
                />
              </div>
            </div>

            <!-- Workplace -->
            <text-field
              v-model="userData.workplaceName"
              id="workplaceName"
              name="workplaceName"
              label="Workplace Name"
              placeholder="Enter Workplace Name"
              :errors="errors"
            />

            <!-- Address -->
            <text-area
              v-model="userData.address"
              id="address"
              name="address"
              label="Address"
              placeholder="Enter Address"
              :errors="errors"
            />

            <div class="row">
              <div class="col">
                <!-- City -->
                <text-field
                  v-model="userData.city"
                  id="city"
                  name="city"
                  label="City"
                  placeholder="Enter City"
                  :errors="errors"
                />
              </div>
              <div class="col">
                <!-- ZIP -->
                <text-field
                  v-model="userData.postcode"
                  id="postcode"
                  name="postcode"
                  label="Postcode"
                  placeholder="Enter Postcode"
                  :errors="errors"
                />
              </div>
            </div>

            <div class="buttons mt-2 mb-3">
              <button
                class="btn btn-sm btn-pompe-secondary"
                type="button"
                @click="goToRequestPass"
              >
                Reset Password
              </button>
              <button class="btn btn-sm btn-pompe-primary" type="submit">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => {
    return {
      content: {},
      logic: null,
      errors: null,
      options: {
        languageOpt: [
          { text: "Choose Language", value: null },
          { text: "German", value: "German" },
          { text: "English", value: "English" },
          { text: "Italian", value: "Italian" },
        ],
      },
      loading: false,
      isFetchingSuccess: true,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    goToRequestPass() {
      location.href = '/web/request-password';
    },
    getData() {
      this.loading = true;

      this.$requestService
        .get(
          this.$apiEndpoints.getCurrentUser,
          {},
          this.handleGetDataSuccess,
          this.handleFailed
        )
        .then(() => {
          this.loading = false;
        });
    },
    handleGetDataSuccess(response) {
      this.content = response.data;
      this.isFetchingSuccess = true;
    },
    updateData() {
      this.loading = true;

      this.$requestService
        .put(
          this.$apiEndpoints.users + `/${this.userData.id}`,
          this.userData,
          this.handleUpdateSuccess,
          this.handleFailed
        )
        .then(() => {
          this.loading = false;
        });
    },
    handleUpdateSuccess() {
      this.$vToastify.success("Successfully Saved");
    },
    handleFailed(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="scss">
.profile-container {
  display: flex;
  justify-content: center;

  &__form {
    width: 800px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}
</style>
